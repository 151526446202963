.summaryContainerAttachments {
    margin: 7em auto;
    max-width: 45%;
}
.summaryContainerContent {
    margin: 2em auto;
    max-width: 45%;
}
@media only screen and (max-width: 600px) {
    .summaryContainerAttachments, .summaryContainerContent{
        margin: .0 auto;
        max-width: 100%;
    }
  }