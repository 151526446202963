.uploadContainer {
    margin: 7em auto;
    max-width: 45%;
    background-color: white;
    padding: 100px 0px;
    height: 75px;
    text-align: center;
}
.uploadPODContainer{
    margin: 7em auto;
    max-width: 45%;
}
.uploadButton {
    text-align: center !important;
}
.submitButton {
    margin: 8em auto 2em auto !important;
    display: block !important;
    width: 45%;
}
.uploadHelpButton { 
    margin: 15px auto 0px auto !important;
    display: block !important;
    align-content: center;
}
@media only screen and (max-width: 600px) {
    .uploadContainer{
        margin: 0 auto;
        max-width: 100%;
        background-color: whitesmoke !important;
        padding-top: 125px;
        padding-bottom: 100px;
        height: 75px;
    }
    .uploadButton {
        background-color: whitesmoke !important;
    }
    .submitButton, .uploadButton {
        width: 55%;
    }
    .uploadPODContainer{
        margin: 0 auto;
        max-width: 100%;
    }
  }
