.loginContainer {
    margin: 5em auto;
    max-width: 45%;
    padding: 3em 1em;
    text-align: center;
}
.logo {
    width: 75px;
    margin: 1em auto 0px auto;
    display: block;
    align-content: center;
}
.loginButton {
    margin: 0px auto !important;
    margin-top: 125px !important;
    padding: 10px 20px !important;
}
.procoreLogo {
    padding-top: 0px;
    width: 1.5em;
    height: 1.5em;
}
.loginButtonLink {
    margin-left: 1em !important;
}
.loginHelpButton { 
    margin: 15px auto 0px auto !important;
    display: block !important;
    align-content: center;
}
@media only screen and (max-width: 600px) {
    .loginContainer{
        margin: 0 auto;
        max-width: 100%;
        padding: auto;
    }
    .loginButton {
        margin-top: 30% !important;
    }
  }