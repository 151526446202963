html,
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  padding: 0;
  margin: 0;
  background-color: whitesmoke;
}
.container{
  margin: 64px auto 0 auto;
}
@media only screen and (max-width: 600px) {
  html, body{
    background-color: white;
  }
}